import { checkKeyInUnknownError } from '@mntn-dev/utilities'

const __AuthError__ = '__AuthError__'

export type AuthErrorType = 'AuthenticationError' | 'AuthorizationError'

type RecognizedErrorCode<Code extends string> = Code | 'unrecognized_error'

type AuthenticationErrorCode<
  Type extends AuthErrorType = 'AuthenticationError',
> = Type extends 'AuthenticationError'
  ? RecognizedErrorCode<
      'auth_missing_session' | 'auth_missing_principal' | 'auth_expired_session'
    >
  : never

type AuthorizationErrorCode<Type extends AuthErrorType = 'AuthorizationError'> =
  Type extends 'AuthorizationError'
    ? RecognizedErrorCode<'auth_not_allowed'>
    : never

type AuthErrorCode<Type extends AuthErrorType> =
  | AuthenticationErrorCode<Type>
  | AuthorizationErrorCode<Type>

abstract class AuthErrorClass<Type extends AuthErrorType> extends Error {
  public readonly tag = __AuthError__

  constructor(
    public readonly type: Type,
    public readonly code: AuthErrorCode<Type>,
    message: string,
    { cause }: { cause?: unknown } = {}
  ) {
    super(message, { cause })
    this.name = this.constructor.name
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export const isAuthError = <Type extends AuthErrorType>(
  cause: unknown,
  type?: Type,
  code?: AuthErrorCode<Type>
): cause is AuthErrorClass<Type> => {
  return (
    checkKeyInUnknownError(cause, 'tag', __AuthError__) &&
    (!type || checkKeyInUnknownError(cause, 'type', type)) &&
    (!code || checkKeyInUnknownError(cause, 'code', code))
  )
}

export class AuthenticationError extends AuthErrorClass<'AuthenticationError'> {
  constructor(
    code: AuthErrorCode<'AuthenticationError'>,
    message: string,
    { cause }: { cause?: unknown } = {}
  ) {
    super('AuthenticationError', code, message, { cause })
  }
}

export class AuthorizationError extends AuthErrorClass<'AuthorizationError'> {
  constructor(
    code: AuthErrorCode<'AuthorizationError'>,
    message: string,
    { cause }: { cause?: unknown } = {}
  ) {
    super('AuthorizationError', code, message, { cause })
  }
}

export class UnauthorizedError extends AuthorizationError {
  constructor(message: string, { cause }: { cause?: unknown } = {}) {
    super('auth_not_allowed', message, { cause })
  }
}
