import {
  AuthenticationError,
  type AuthenticatedSession,
  type UnauthenticatedSession,
} from '@mntn-dev/auth-types'

import { sessionHasPrincipal } from './session-has-principal.ts'
import { sessionIsExpired } from './session-is-expired.ts'

export const authenticateSession = (
  session: UnauthenticatedSession
): AuthenticatedSession => {
  if (!session) {
    throw new AuthenticationError('auth_missing_session', 'No session found')
  }

  if (sessionIsExpired(session)) {
    throw new AuthenticationError('auth_expired_session', 'Session expired')
  }

  if (!sessionHasPrincipal(session)) {
    throw new AuthenticationError(
      'auth_missing_principal',
      'No session principal found'
    )
  }

  return session
}
