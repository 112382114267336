import type { RequireAtLeastOne } from 'type-fest'

import type { AgencyId, BrandId, FeedId, UserId } from '@mntn-dev/domain-types'
import { env } from '@mntn-dev/env'
import {
  type RealtimeSendFunction,
  agencyChannelTag,
  brandChannelTag,
  feedChannelTag,
  internalChannelTag,
  userChannelTag,
} from '@mntn-dev/realtime-updates-types'

import { innerSendMessage, requestToken } from './adapted-services.ts'
import { realtimeUpdateLogger } from './realtime-update-logger.ts'
import type { RealtimeSchema } from './schemas.ts'

export type ChannelIds = RequireAtLeastOne<
  {
    brandId: BrandId
    agencyId: AgencyId
    userId: UserId
    feedId: FeedId
  },
  'brandId' | 'agencyId' | 'userId' | 'feedId'
>

const sendMessage: RealtimeSendFunction<RealtimeSchema, ChannelIds> = (
  channelIds,
  type,
  message
) => {
  if (env.DISABLE_ASYNC_MESSAGES === 'true') {
    return 0
  }

  const { agencyId, brandId, userId, feedId } = channelIds

  const channels = [
    brandId && `${brandChannelTag}:${brandId}`,
    agencyId && `${agencyChannelTag}:${agencyId}`,
    userId && `${userChannelTag}:${userId}`,
    internalChannelTag,
    feedId ? `${feedChannelTag}:${feedId}` : undefined,
  ].filter((x: string | undefined): x is string => !!x)

  realtimeUpdateLogger.debug('Sending realtime message --->', {
    channels,
    type,
    message,
  })

  return innerSendMessage(channels, type, message)
}

export const realtimeServerService = { sendMessage, requestToken }
