import type {
  AuthorizedSession,
  SystemSession,
  SystemSessionJson,
} from '@mntn-dev/auth-types'
import {
  type UserDomainQueryModel,
  UserId,
  UserUrn,
} from '@mntn-dev/domain-types'
import { createPermissions } from './permissions/create-permissions.ts'

export const SystemUserId = UserId.Empty

export const SystemUser = <const>{
  emailAddress: 'system@quickframe.com',
  userType: 'system',
  firstName: 'System',
  lastName: 'User',
  displayName: 'System User',
  initials: 'SU',
  authConnection: 'Username-Password-Authentication',
  isActive: true,
  userId: SystemUserId,
  userUrn: UserUrn(SystemUserId),
}

export type SystemUser = typeof SystemUser

export const isSystemUser = (
  user: Pick<UserDomainQueryModel | SystemUser, 'userId' | 'userType'>
): user is SystemUser => {
  const systemUser = user as SystemUser
  return (
    systemUser?.userId === SystemUser.userId &&
    systemUser?.userType === SystemUser.userType
  )
}

export const systemSessionFromAuthorizedSession = (
  session: AuthorizedSession
): SystemSession => {
  const systemSession: SystemSession = {
    ...session,
    principal: {
      ...session.principal,
      user: {
        userId: SystemUser.userId,
        emailAddress: SystemUser.emailAddress,
        userType: SystemUser.userType,
      },
      ...createPermissions({ userType: SystemUser.userType }),
    },
  } as const

  return systemSession
}

export const rehydrateSystemSession = (
  session: SystemSession | SystemSessionJson
): SystemSession => {
  const systemSession: SystemSession = {
    ...session,
    principal: {
      ...session.principal,
      ...createPermissions({ userType: session.principal.user.userType }),
    },
  } as const

  return systemSession
}
