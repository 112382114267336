'use client'

import { useRefetchBuilder } from '~/app/hooks/index.ts'
import { useStore } from '~/store/index.ts'

export const useRefetchNotifications = () => {
  const refetchBuilder = useRefetchBuilder()
  const { me } = useStore()

  return () =>
    me &&
    refetchBuilder
      .include((utils) =>
        utils.notifications.getNotificationsForUser.refetch({
          userId: me.userId,
        })
      )
      .fetch()
}
