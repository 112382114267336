import type {
  AuthenticatedSession,
  UnauthenticatedSession,
} from '@mntn-dev/auth-types'

/**
 * Checks if the provided session object has an email address.
 *
 * @param session - The session object to check. It can be of type Session, null, or undefined.
 * @returns A boolean indicating whether the session object has a principal with a userId and email address.
 */
export function sessionHasPrincipal(
  session: NonNullable<UnauthenticatedSession>
): session is AuthenticatedSession {
  return session.principal !== undefined
}
