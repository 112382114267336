import type { AgencyId, BrandId, FeedId, UserId } from '@mntn-dev/domain-types'

export const brandChannelTag = 'brand' as const
export const agencyChannelTag = 'agency' as const
export const userChannelTag = 'user' as const
export const internalChannelTag = 'internal' as const
export const feedChannelTag = 'feed' as const

export type BrandChannelName = `${typeof brandChannelTag}:${BrandId}`
export type AgencyChannelName = `${typeof agencyChannelTag}:${AgencyId}`
export type UserChannelName = `${typeof userChannelTag}:${UserId}`
export type InternalChannelName = typeof internalChannelTag
export type FeedChannelName = `${typeof feedChannelTag}:${FeedId}`

export type ChannelName =
  | BrandChannelName
  | AgencyChannelName
  | UserChannelName
  | InternalChannelName
  | FeedChannelName
