import { Surface, type SurfaceSectionProps } from '@mntn-dev/ui-components'
import { themeHoverBackgroundMap } from '@mntn-dev/ui-theme'

export const NotificationSurfaceSection = ({
  children,
  ...props
}: SurfaceSectionProps) => {
  return (
    <Surface.Section
      {...props}
      className={`cursor-pointer ${themeHoverBackgroundMap.tertiary}`}
    >
      {children}
    </Surface.Section>
  )
}
