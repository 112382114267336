import { useMemo } from 'react'

import { createPermissions } from '@mntn-dev/session-manager'

import { useStore } from '~/store/index.ts'

function usePermissions() {
  const userType = useStore((state) => state.me?.userType)

  const permissions = useMemo(() => createPermissions({ userType }), [userType])

  return permissions
}

export { usePermissions }
