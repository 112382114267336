import {
  AuthenticationError,
  type AuthenticatedSession,
  type AuthorizedSession,
  type AuthorizedSessionJSON,
  type SystemSession,
  type UnauthenticatedSession,
} from '@mntn-dev/auth-types'
import type { UserDomainQueryModel } from '@mntn-dev/domain-types'

import { authenticateSession } from './authenticate-session.ts'
import {
  authorizeSessionFromUser,
  rehydrateAuthorizedSession,
} from './authorize-session.ts'
import {
  systemSessionFromAuthorizedSession,
  type SystemUser,
} from './system-session.ts'
import type { SessionManagerInstance, SessionManagerState } from './types.ts'

function SessionManagerInternal(
  state: SessionManagerState
): SessionManagerInstance {
  const authenticate = () => {
    if (state.auth.status === 'unauthenticated') {
      state.auth = {
        session: authenticateSession(state.auth.session),
        status: 'authenticated',
      }
    }

    return state.auth.session
  }

  const authorize = (
    user: UserDomainQueryModel | SystemUser
  ): AuthorizedSession => {
    state.auth = {
      session: authorizeSessionFromUser(authenticate(), user),
      status: 'authorized',
    }

    return state.auth.session
  }

  const assertAuthorizedSession = (): AuthorizedSession => {
    if (state.auth.status !== 'authorized') {
      throw new AuthenticationError(
        'unrecognized_error',
        'Session was not authorized'
      )
    }

    return state.auth.session
  }

  const system = (): SystemSession => {
    state.auth = {
      session: systemSessionFromAuthorizedSession(assertAuthorizedSession()),
      status: 'system',
    }

    return state.auth.session
  }

  return {
    authorize,
    authenticate,
    assertAuthorizedSession,
    system,
  } as const
}

export const fromUnauthenticatedSession = (session: UnauthenticatedSession) => {
  return SessionManagerInternal({
    auth: {
      session,
      status: 'unauthenticated',
    },
  })
}

export const fromAuthenticatedSession = (session: AuthenticatedSession) => {
  return SessionManagerInternal({
    auth: {
      session,
      status: 'authenticated',
    },
  })
}

export const fromAuthorizedSession = (
  session: AuthorizedSession | AuthorizedSessionJSON
) => {
  return SessionManagerInternal({
    auth: {
      session: rehydrateAuthorizedSession(session),
      status: 'authorized',
    },
  })
}
