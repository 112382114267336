import { StatusCodes } from 'http-status-codes'

import { asArray } from '@mntn-dev/utilities'

import { ablyRest } from './ably-rest.ts'

export const sendViaAbly = (
  channels: string | string[],
  messages: object | object[]
): Promise<number> => {
  // Ensure channels and messages are arrays
  const content = { channels: asArray(channels), messages: asArray(messages) }

  const result = new Promise<number>((resolve, reject) => {
    ablyRest?.request('post', '/messages', null, content, null, (err, res) => {
      if (err) {
        reject(err)
      } else {
        resolve(res?.statusCode ?? StatusCodes.INTERNAL_SERVER_ERROR)
      }
    })
  })

  return result
}
