import { Activity } from '@mntn-dev/app-activity'
import {
  NotificationModal,
  NotificationSurface,
} from '@mntn-dev/app-notification'
import type { NotificationId } from '@mntn-dev/domain-types'
import {
  LoadingCenter,
  LoadingModal,
  type ModalProps,
} from '@mntn-dev/ui-components'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { trpcReactClient } from '~/app/_trpc/trpc-react-client.ts'
import { toActivityModel } from '~/lib/activity/activity-helper.ts'
import { useStore } from '~/store/index.ts'
import { NextImage } from '../image/next-image.tsx'
import { EmptyPlaceholder } from '../misc/empty-placeholder.tsx'
import { useNotificationsQuery } from './hooks/use-notifications-query.ts'

type NotificationsProps = Omit<ModalProps, 'children'>

const Notifications = ({ ...props }: NotificationsProps) => {
  const { onClose } = props
  const { me, lng } = useStore()
  const router = useRouter()

  const { notifications, refetchNotifications, unreadNotificationCount } =
    useNotificationsQuery()

  const { mutateAsync: markNotificationRead } =
    trpcReactClient.notifications.markNotificationRead.useMutation()

  const { mutateAsync: markNotificationsReadForUser } =
    trpcReactClient.notifications.markNotificationsReadForUser.useMutation()

  const handleMarkAllAsReadClick = async () => {
    if (me) {
      await markNotificationsReadForUser({ userId: me?.userId })
      await refetchNotifications()
      onClose()
    }
  }

  const handleNotificationClick =
    (notificationId: NotificationId) => async () => {
      await markNotificationRead({ notificationId })
      await refetchNotifications()
    }

  const [loading, setLoading] = useState(false)
  const pathname = usePathname()

  // biome-ignore lint/correctness/useExhaustiveDependencies: pathname is a trigger
  useEffect(() => {
    setLoading(false)
  }, [pathname])

  const handleNavigate = (url: string) => {
    if (pathname !== url) {
      onClose()
      setLoading(true)
      setTimeout(() => {
        router.push(url)
      }, 200)
    }
  }

  return (
    <>
      <NotificationModal {...props}>
        <NotificationSurface
          {...{
            lng,
            onClose,
            onMarkAllAsRead: handleMarkAllAsReadClick,
            unreadNotificationCount,
          }}
        >
          {notifications ? (
            notifications.length > 0 ? (
              notifications.map((notification) => {
                const { status, activityId, activity } = notification

                return (
                  activity && (
                    <NotificationSurface.Section
                      key={activityId}
                      onClick={handleNotificationClick(
                        notification.notificationId
                      )}
                    >
                      <Activity
                        image={NextImage({ unoptimized: true })}
                        lng={lng}
                        onNavigate={handleNavigate}
                        currentUrl={pathname}
                        activity={toActivityModel(activity)}
                        indicator={status === 'unread'}
                        observerUserId={me?.userId}
                        treatment="notification"
                      />
                    </NotificationSurface.Section>
                  )
                )
              })
            ) : (
              <EmptyPlaceholder />
            )
          ) : (
            <LoadingCenter />
          )}
        </NotificationSurface>
      </NotificationModal>
      <LoadingModal onClose={() => setLoading(false)} open={loading} />
    </>
  )
}

export { Notifications, type NotificationsProps }
