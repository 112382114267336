import { Realtime } from 'ably'

import { runningOnServer } from '@mntn-dev/utilities'

let _realtime: Realtime | undefined

const realtime = () => {
  if (_realtime) {
    return _realtime
  }

  if (!runningOnServer()) {
    _realtime = new Realtime({ authUrl: '/api/auth/realtime' })
    return _realtime
  }

  return undefined
}

export { realtime }
