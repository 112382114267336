import type { Types } from 'ably'

import type {
  RealtimeEventSchema,
  RealtimeEventType,
  RealtimeHandlerData,
  RealtimeMessageHandlerFunction,
  RealtimeSendFunction,
  RealtimeSubscribeFunction,
} from '@mntn-dev/realtime-updates-types'

import { realtime } from './ably-realtime.ts'
import { requestToken } from './request-token.ts'
import { sendViaAbly } from './send-via-ably.ts'

export function ablyAdapter<S extends RealtimeEventSchema>() {
  const sendMessage: RealtimeSendFunction<S> = async (
    channels,
    type,
    payload
  ) => {
    const sendPayload = { name: type, data: payload }
    const result = await sendViaAbly(channels, sendPayload)
    return result
  }

  const getChannel = (channelName: string) => {
    const realtimeClient = realtime()
    return realtimeClient?.channels.get(channelName)
  }

  const subscribe = <Type extends RealtimeEventType<S>>(
    channel: Types.RealtimeChannelCallbacks,
    messageType: Type,
    handler: RealtimeSubscribeFunction<S, Type>
  ) => {
    channel.subscribe(messageType.toString(), (message) => {
      handler(message as unknown as RealtimeHandlerData<S, Type>)
    })
  }

  const handleAllMessages = (
    channel: Types.RealtimeChannelCallbacks,
    handler: RealtimeMessageHandlerFunction<S>
  ) => {
    channel.subscribe((message) => {
      handler(message)
    })
  }

  return { sendMessage, getChannel, subscribe, handleAllMessages, requestToken }
}
