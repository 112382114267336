'use client'

import { usePathname, useRouter } from 'next/navigation'

import { useTranslation } from '@mntn-dev/i18n'
import {
  type NavigationIconItemProps,
  getTestProps,
  useOpenState,
} from '@mntn-dev/ui-components'

import { route } from '@mntn-dev/app-routing'
import Image from 'next/image'
import { usePermissions } from '~/auth/hooks/use-permissions.ts'
import { usePersonImageUrlInterceptor } from '~/components/avatar/use-person-image-url-interceptor.tsx'
import { NextImage } from '~/components/image/next-image.tsx'
import { Notifications } from '~/components/notification/notifications.tsx'
import { useStore } from '~/store'

import { useFlags } from '@mntn-dev/flags-client'
import { Navigation } from '@mntn-dev/ui-components'
import { userHighlightMap } from '~/components/avatar/helper.ts'
import { useNotificationsQuery } from '~/components/notification/hooks/use-notifications-query.ts'

type NavigationItem = NavigationIconItemProps & {
  authorized: () => boolean
  unreadNotificationCount?: number
}

export const Navbar = () => {
  const { lng, me } = useStore()
  const { t } = useTranslation(lng, 'navbar')
  const router = useRouter()
  const currentPage = usePathname()
  const permissions = usePermissions()
  const { useCustomIntercomLauncher } = useFlags()

  const originalPerson = {
    firstName: me?.firstName ?? '',
    lastName: me?.lastName ?? '',
    imageUrl: me?.avatarStorageKey ?? '',
  }

  const { person } = usePersonImageUrlInterceptor(originalPerson, {
    height: 116,
    width: 116,
  })

  const selected = (id: string) =>
    currentPage === `/${id}` || currentPage.startsWith(`/${id}/`)

  const notificationsOpenState = useOpenState()
  const { unreadNotificationCount } = useNotificationsQuery()

  const navigationMap: NavigationItem[] = [
    {
      id: 'dashboard',
      iconName: 'nav-projects',
      title: t('projects'),
      onClick: () => {
        router.push(route('/projects').toRelativeUrl())
      },
      authorized: () => true,
      selected,
      dataTestId: 'main-app-navigation-bar-item-projects',
    },
    {
      id: 'alerts',
      iconName: 'nav-bell',
      title: t('alerts'),
      onClick: notificationsOpenState.onToggle,
      authorized: () => true,
      selected,
      unreadNotificationCount,
    },
    {
      id: 'users',
      iconName: 'nav-users',
      title: t('users'),
      onClick: () => {
        router.push(route('/users').toRelativeUrl())
      },
      authorized: () => permissions.canAdministerUsers(),
      selected,
    },
    {
      id: 'packages',
      iconName: 'nav-packages',
      title: t('packages'),
      onClick: () => {
        router.push(route('/packages').toRelativeUrl())
      },
      authorized: () => permissions.canAdministerPackages(),
      selected,
    },
  ]

  const handleLogoClick = () => {
    router.push(route('/projects').toRelativeUrl())
  }

  const handleAvatarClick = () => {
    router.push(route('/profile').toRelativeUrl())
  }

  return (
    <Navigation>
      <Navigation.Header>
        <button
          type="button"
          onClick={handleLogoClick}
          {...getTestProps({ dataTestId: 'main-app-navigation-bar-header' })}
        >
          <Image
            src="/images/quickframe-logo-nav.svg"
            width={54}
            height={36}
            alt="QuickFrame"
            className="h-9 w-[54px]"
          />
        </button>
      </Navigation.Header>
      <Navigation.Main>
        <Navigation.Items>
          {navigationMap.map((itemProps) => {
            if (itemProps.authorized()) {
              return <Navigation.IconItem key={itemProps.id} {...itemProps} />
            }
          })}
        </Navigation.Items>
        <Navigation.Items>
          {useCustomIntercomLauncher && (
            <Navigation.ButtonItem
              id="intercom-launcher"
              iconLeft="QuestionMarkCircleIcon"
              iconFill="solid"
              size="sm"
            />
          )}
          <Navigation.AvatarItem
            dataTestId="main-app-navigation-bar-item-profile"
            borderColor={userHighlightMap[me?.userType ?? 'brand']}
            person={person}
            image={NextImage({ unoptimized: true })}
            onClick={handleAvatarClick}
            selected={() => selected('profile')}
          />
        </Navigation.Items>
      </Navigation.Main>
      <Notifications {...notificationsOpenState} />
    </Navigation>
  )
}
