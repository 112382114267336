import { FileId, FileIdSchema } from '@mntn-dev/domain-types'
import type { ImageTransformationOptions } from '@mntn-dev/files-shared'
import type { Person } from '@mntn-dev/ui-components'
import { satisfiesSchema } from '@mntn-dev/utility-types'
import { getFileImageProxyUrl } from '~/utils/client/file-utilities.ts'

export const usePersonImageUrlInterceptor = (
  person: Person,
  options?: ImageTransformationOptions
) => {
  if (!satisfiesSchema(FileIdSchema, person.imageUrl)) {
    return { person }
  }

  const fileId = FileId(person.imageUrl)

  const imageUrl = getFileImageProxyUrl({
    fileId,
    options: {
      height: 200,
      width: 200,
      gravity: 'custom',
      crop: 'thumb',
      ...options,
    },
  })

  const interceptedPerson = { ...person, imageUrl: imageUrl }
  return { person: interceptedPerson }
}
