import type { Session } from '@auth0/nextjs-auth0'
import type {
  Merge,
  OmitIndexSignature,
  OverrideProperties,
  SetOptional,
} from 'type-fest'

import {
  AgencyId,
  BrandId,
  type FileArea,
  SessionId,
  type UserDomainSelectModel,
  UserId,
  type UserType,
} from '@mntn-dev/domain-types'

import {
  type AuthErrorType,
  AuthenticationError,
  AuthorizationError,
  UnauthorizedError,
  isAuthError,
} from './auth-error.ts'

/**
 * Session (aka AuthenticatedSession that we interface-merge with auth0 below)
 */

type AuthenticatedSessionPrincipalUser = {
  userId: UserId
  emailAddress: UserDomainSelectModel['emailAddress']
}

type AuthenticatedSessionPrincipal = {
  user: AuthenticatedSessionPrincipalUser
  scopes: string[]
}

declare module '@auth0/nextjs-auth0' {
  interface Session {
    sessionId: SessionId
    principal: AuthenticatedSessionPrincipal
  }
}

type AuthenticatedSession = OmitIndexSignature<Session>

/**
 * UnauthenticatedSession
 */

type UnauthenticatedSession =
  | SetOptional<AuthenticatedSession, 'principal'>
  | null
  | undefined

/**
 * AuthorizedSession
 */

type Permissions = {
  canAcceptOffer: () => boolean
  canAdministerPackages: () => boolean
  canAdministerProjects: () => boolean
  canAdministerUsers: () => boolean
  canAdministerWatches: () => boolean
  canArchiveProject: () => boolean
  canCommentOnProject: () => boolean
  canCompleteProject: () => boolean
  canCreateProject: () => boolean
  canCreateReview: () => boolean
  canCreateWatch: () => boolean
  canDeclineOffer: () => boolean
  canDeleteProject: () => boolean
  canDeleteWatch: () => boolean
  canEditMatch: () => boolean
  canEditProject: () => boolean
  canRemoveMatch: () => boolean
  canSubmitProject: () => boolean
  canSubscribeToInternalUpdates: () => boolean
  canUpdateWatch: () => boolean
  canUploadFile: (fileType: FileArea) => boolean
  canViewActivity: () => boolean
  canViewAgency: () => boolean
  canViewAllActivity: () => boolean
  canViewAllWatches: () => boolean
  canViewPackages: () => boolean
  canViewBrand: () => boolean
  canViewInternalPricingNote: () => boolean
  canViewMatch: () => boolean
  canViewOffer: () => boolean
  canViewProject: () => boolean
  canViewWatch: () => boolean
  canViewCost: () => boolean
  canViewCostPlusMargin: () => boolean
}

type WithPermissions<T> = Merge<T, Permissions>

type AuthorizedSessionJSON = OverrideProperties<
  AuthenticatedSession,
  {
    sessionId: SessionId
    principal: {
      user: {
        userId: UserId
        emailAddress: UserDomainSelectModel['emailAddress']
        // This field is our "role" in the short-term until we build out a full RBAC system
        userType: UserType | 'system'
        // These fields are not strictly needed to generate permissions but it makes project permission checks a lot simpler
        brandId?: BrandId
        agencyId?: AgencyId
      }
      scopes: string[]
    }
  }
>

type AuthorizedSession = OverrideProperties<
  AuthorizedSessionJSON,
  {
    principal: WithPermissions<AuthorizedSessionJSON['principal']>
  }
>

/**
 * System Session
 */
type SystemSessionJson = OverrideProperties<
  AuthorizedSession,
  {
    sessionId: SessionId
    principal: {
      user: {
        userId: UserId
        emailAddress: UserDomainSelectModel['emailAddress']
        userType: 'system'
      }
      scopes: string[]
    }
  }
>

type SystemSession = OverrideProperties<
  AuthorizedSession,
  {
    principal: WithPermissions<SystemSessionJson['principal']>
  }
>

/**
 * Example of AuthorizedSession below
 */
const permissions = { canViewProject: () => true } as Permissions

const _authorizedSessionExample: AuthorizedSession = {
  sessionId: SessionId(),
  principal: {
    user: {
      userId: UserId(),
      emailAddress: 'test@mountain.com',
      userType: 'brand',
      brandId: BrandId(),
      agencyId: AgencyId(),
    },
    scopes: [],
    ...permissions,
  },
  user: {},
}

export type {
  UnauthenticatedSession,
  AuthenticatedSession,
  AuthenticatedSessionPrincipal,
  AuthenticatedSessionPrincipalUser,
  AuthorizedSession,
  AuthorizedSessionJSON,
  SystemSession,
  SystemSessionJson,
  Permissions,
  AuthErrorType,
}

export {
  isAuthError,
  AuthenticationError,
  AuthorizationError,
  UnauthorizedError,
}
