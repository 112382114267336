import type {
  AuthenticatedSession,
  AuthorizedSession,
  AuthorizedSessionJSON,
} from '@mntn-dev/auth-types'
import type { UserDomainQueryModel } from '@mntn-dev/domain-types'

import { createPermissions } from './permissions/create-permissions.ts'
import { type SystemUser, isSystemUser } from './system-session.ts'

export const authorizeSessionFromUser = (
  session: AuthenticatedSession | AuthorizedSession,
  user: UserDomainQueryModel | SystemUser
): AuthorizedSession => {
  const authorizedSession: AuthorizedSession = {
    ...session,
    principal: {
      ...session.principal,
      user: {
        userId: user.userId,
        emailAddress: user.emailAddress,
        userType: user.userType,
        ...(isSystemUser(user)
          ? {}
          : {
              brandId: user.brand?.brandId,
              agencyId: user.agency?.agencyId,
            }),
      },
      ...createPermissions({ userType: user.userType }),
    },
  } as const

  return authorizedSession
}

export const rehydrateAuthorizedSession = (
  session: AuthorizedSession | AuthorizedSessionJSON
): AuthorizedSession => {
  const authorizedSession: AuthorizedSession = {
    ...session,
    principal: {
      ...session.principal,
      ...createPermissions({ userType: session.principal.user.userType }),
    },
  } as const

  return authorizedSession
}
