'use client'

import { useEffect, useRef } from 'react'

import type {
  ChannelName,
  RealtimeEventType,
  RealtimeSubscribeFunction,
} from '@mntn-dev/realtime-updates-types'

import { realtimeClientService } from './realtime-client-service.ts'
import { realtimeUpdateLogger } from './realtime-update-logger.ts'
import type { RealtimeSchema } from './schemas.ts'

const useRealtimeSubscription = <T extends RealtimeEventType<RealtimeSchema>>(
  channelName: ChannelName,
  messageType: T,
  messageHandler: RealtimeSubscribeFunction<RealtimeSchema, T>
) => {
  const channel = useRef<ReturnType<typeof realtimeClientService.getChannel>>()

  if (
    channelName &&
    (!channel.current || channel.current.name !== channelName)
  ) {
    channel.current = realtimeClientService.getChannel(channelName)
  }

  useEffect(() => {
    const messageHandlerWrapper: RealtimeSubscribeFunction<
      RealtimeSchema,
      T
    > = (message) => {
      realtimeUpdateLogger.debug(
        '---> Handling realtime message (useRealtimeSubscription)',
        { message }
      )
      return messageHandler(message)
    }

    if (channel.current) {
      realtimeClientService.subscribe(
        channel.current,
        messageType,
        messageHandlerWrapper
      )
    }

    return () => {
      if (channel.current) {
        channel.current.unsubscribe()
        channel.current.detach()
      }
    }
  })

  return channel.current
}

export { useRealtimeSubscription }
