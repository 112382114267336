import { AuthorizedSystemSession } from './authorized-system-session.ts'
import {
  fromAuthenticatedSession,
  fromAuthorizedSession,
  fromUnauthenticatedSession,
} from './manager.ts'

export * from './permissions/index.ts'

export const SessionManager = {
  fromUnauthenticatedSession,
  fromAuthenticatedSession,
  fromAuthorizedSession,
  AuthorizedSystemSession,
} as const

export { SystemUserId } from './system-session.ts'
