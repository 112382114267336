import { ablyRest } from './ably-rest.ts'

export async function requestToken() {
  const result = new Promise<string>((resolve, reject) => {
    if (!ablyRest) {
      throw new Error('Ably Rest client not initialized.')
    }

    ablyRest.auth.requestToken(null, (err, tokenDetails) => {
      if (err) {
        reject(err)
      } else if (tokenDetails) {
        resolve(tokenDetails.token)
      }

      reject(new Error('No token details returned from Ably'))
    })
  })

  return result
}
