import { ablyAdapter } from '@mntn-dev/realtime-updates-ably-adapter'

import type { RealtimeSchema } from './schemas.ts'

export const {
  getChannel,
  handleAllMessages,
  requestToken,
  sendMessage: innerSendMessage,
  subscribe,
} = ablyAdapter<RealtimeSchema>()
